import React from 'react';
import styled from 'styled-components';

export default function PrintPreview(props) {
  const {loading} = props;

  if (loading) {
    return (
      <Wrapper>
        <div className="center-container">
          <h2>Loading...</h2>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="backdrop">{props.children}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0;
  padding: 0;

  & .center-container {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .backdrop {
    border: 1px solid #fafafa;
    background-color: #fafafa;
  }

  & .page {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  @page {
    size: A4 portrait;
    margin: 0 auto;
  }

  @media print {
    .sidebar {
      display: none;
    }

    html,
    body {
      width: 210mm;
      height: 297mm;
    }
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;

      :last-child {
        page-break-after: avoid;
      }
    }
  }
`;
