import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import withPageEntry from '../../withPageEntry';
import PrintPreview from '../../Components/PrintPreview';
import {groupStudyHoursByYear} from '../../Components/TwpaaUserStudyHoursButton';
import {renderCategoryOption} from '../../Components/TwpaaCourseItem';

function PrintStudyHoursPreview(props) {
  const [user] = useOutlet('user');
  const [fetchResp, setFetchResp] = React.useState(null);
  const {year: selectedYear, type: selectedHoursType} = qs.parse(
    props.location.search,
  );

  const fetchDocumentList = React.useCallback(async () => {
    if (!user) {
      return;
    }

    AppActions.setLoading(true);
    try {
      const extraQuery = {
        id_card_number: user.data?.id_card_number,
      };

      if (selectedYear) {
        extraQuery.date = {$regex: selectedYear.toString()};
      }

      if (selectedHoursType) {
        extraQuery[`hours_catagory.${selectedHoursType}`] = true;
      }

      const resp = await JStorage.fetchDocuments(
        'study_hour',
        extraQuery,
        ['-date'],
        {offset: 0, limit: 1000},
      );

      resp.results = groupStudyHoursByYear(resp.results);
      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [user, selectedYear, selectedHoursType]);

  React.useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList, user]);

  if (!user || !fetchResp) {
    return <PrintPreview loading={true} />;
  }

  return (
    <PrintPreview>
      <div className="page">
        <Ant.Table
          columns={[
            {
              title: '日期',
              key: 'date',
              dataIndex: 'date',
            },
            {
              title: '活動名稱',
              key: 'course_name',
              render: (_, record) => {
                if (record.year) {
                  return (
                    <div style={{textAlign: 'right', fontWeight: 'bold'}}>
                      {record.year}年小計
                    </div>
                  );
                }
                return record.course_name;
              },
            },
            {
              title: '時數',
              key: 'hours',
              dataIndex: 'hours',
            },
            {
              title: '時數類別',
              key: 'category',
              render: (_, record) => {
                if (record.sums) {
                  return (
                    <div>
                      {Object.keys(record.sums).map((k) => {
                        const labels = {
                          option_1: '獎',
                          option_2: '職',
                          option_3: '訴',
                          option_4: '商',
                        };
                        return (
                          <div key={k}>
                            {labels[k]}/{record.sums[k]}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                return (
                  <CourseCat>
                    {record.hours_catagory?.option_1 &&
                      renderCategoryOption('option_1')}
                    {record.hours_catagory?.option_2 &&
                      renderCategoryOption('option_2')}
                    {record.hours_catagory?.option_3 &&
                      renderCategoryOption('option_3')}
                    {record.hours_catagory?.option_4 &&
                      renderCategoryOption('option_4')}
                  </CourseCat>
                );
              },
            },
          ]}
          dataSource={fetchResp?.results}
          rowKey={'id'}
          pagination={{
            size: 'small',
            total: fetchResp?.total || 0,
            showSizeChanger: false,
            position: ['bottomRight'],
          }}
        />
      </div>
    </PrintPreview>
  );
}

const CourseCat = styled.ul`
  display: flex;
  gap: 6px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }
`;

export default withPageEntry(PrintStudyHoursPreview);
